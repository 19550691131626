import { FC } from "react";
import { FaIcon } from "../../../components/fontawesome/icon";
import {
  faBuilding,
  faCalendar,
  faLocation,
  faPerson,
} from "@fortawesome/free-solid-svg-icons";
import { toRelativeCalendar } from "../../../../lib/date";
import { Link } from "react-router-dom";
import { PathImageLoader } from "../../../components/imageloader/PathImageLoader";
import { UserSupervision } from "../../../../domain/supervision/Supervision";

export const UserSupervisionView: FC<{
  supervision: UserSupervision;
}> = ({ supervision }) => {
  function verifyValue(value: any) {
    if (typeof value === "string") {
      return value;
    } else {
      return "no ingresado";
    }
  }

  return (
    <div
      className={
        "w-full h-full overflow-auto flex flex-row flex-wrap gap-x-4 py-4"
      }
    >
      <p className={"px-2 m-0 text-neutral-900 text-lg uppercase grow"}>
        Responsable:{" "}
        {verifyValue(supervision.formDataPageMain.controlLiable.value)}
      </p>
      <div className={"w-full flex flex-col p-2 rounded items-start gap-2"}>
        <div className={"flex flex-col flex-wrap w-fit gap-y-4"}>
          <div className={"flex flex-col gap-2"}>
            <p className={"text-neutral-400 text-md uppercase grow"}>GENERAL</p>
            <div
              className={
                "w-full flex flex-row flex-wrap gap-x-6 gap-y-2 items-center"
              }
            >
              {/* <div>
                <FaIcon icon={faPerson} className={"mr-2 text-neutral-400"} />
                <Link to={`/dashboard/users/${ownerUid}`}>
                  {owner ? owner.displayName : submoduleId}
                </Link>
              </div> */}

              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <FaIcon icon={faCalendar} className={"text-neutral-400"} />
                <span className={"font-semibold uppercase"}>Registrado el</span>
                {supervision.timestamp
                  ? (toRelativeCalendar(supervision.timestamp), true)
                  : null}
              </div>

              <div>
                <FaIcon icon={faBuilding} className={"mr-2 text-neutral-400"} />
                {supervision.formDataPageMain.controlUnity.value}
                {/* <Link to={`/dashboard/unity/${unityId}`}>
                  {unity ? unity.label : unityId}
                </Link> */}
              </div>

              {/* <div>
                <FaIcon icon={faLocation} className={"mr-2 text-neutral-400"} />
                <Link
                  to={`/dashboard/unity/${unityId}/zoneView/${submoduleId}`}
                >
                  {zone ? zone.label : submoduleId}
                </Link>
              </div> */}
            </div>
          </div>

          {/* <div className={"flex flex-col gap-y-2 justify-start items-start"}>
            <p className={"text-neutral-400 text-md uppercase grow"}>
              FOTO DE RONDA
            </p>
            <PathImageLoader
              path={ownerPhoto}
              className={"object-cover aspect-square rounded-2xl max-h-96"}
            />
          </div> */}

          <div className={"flex flex-col gap-y-2 justify-start items-start"}>
            <p className={"text-neutral-400 text-md uppercase grow"}>
              Información General
            </p>
            <div className={"flex flex-col w-full"}>
              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>Dia </span>
                {supervision.formDataPageMain.controlDate.value}
              </div>

              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>Hora Entrada </span>
                {supervision.formDataPageMain.controlEntry.value}
              </div>

              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>Hora Salida </span>
                {supervision.formDataPageMain.controlDeparture.value}
              </div>

              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>Unidad </span>
                {supervision.formDataPageMain.controlUnity.value}
              </div>

              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>
                  Personal Operativo{" "}
                </span>
                {supervision.formDataPageMain.operatingStaff.value}
              </div>

              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>
                  Cuaderno de ocurrencias{" "}
                </span>
                {supervision.formDataPageMain.occurrenceBook.value === true
                  ? "Si"
                  : "No"}
              </div>

              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>
                  Ultima visita del supervisor{" "}
                </span>
                {supervision.formDataPageMain.lastSupervisorVisit.value}
              </div>

              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}> Observación </span>
                {supervision.formDataPageMain.controlMainComment.value}
              </div>

              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>
                  {" "}
                  Responsable de Supervisión{" "}
                </span>
                {supervision.formDataPageMain.controlLiable.value}
              </div>

              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>
                  {" "}
                  Representante de cliente - GDS{" "}
                </span>
                {supervision.formDataPageMain.controlGds.value}
              </div>
            </div>
          </div>

          <div className={"flex flex-col gap-y-2 justify-start items-start"}>
            <p className={"text-neutral-400 text-md uppercase grow"}>
              Pertenencias
            </p>
            <div className={"flex flex-col w-full"}>
              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>
                  Modelo Celular
                </span>
                {supervision.formDataPageBelongings.mediaModel.value}
              </div>

              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>Estado</span>
                {supervision.formDataPageBelongings.mediaState.value}
              </div>

              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>Cargador</span>
                {supervision.formDataPageBelongings.mediaCharger.value === true
                  ? "Si"
                  : "No"}
              </div>

              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>Hands Free</span>
                {supervision.formDataPageBelongings.mediaHandsFree.value ===
                true
                  ? "Si"
                  : "No"}
              </div>

              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>Antena</span>
                {supervision.formDataPageBelongings.mediaAerialRadio.value ===
                true
                  ? "Si"
                  : "No"}
              </div>

              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>
                  Detector de metal
                </span>
                {supervision.formDataPageBelongings.metalDetector.value === true
                  ? "Si"
                  : "No"}
              </div>

              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>Linternas</span>
                {supervision.formDataPageBelongings.lantern.value === true
                  ? "Si"
                  : "No"}
              </div>

              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>Baston Espejo</span>
                {supervision.formDataPageBelongings.mirrorCane.value === true
                  ? "Si"
                  : "No"}
              </div>

              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>
                  Pulsador de panico
                </span>
                {supervision.formDataPageBelongings.pannicButton.value === true
                  ? "Si"
                  : "No"}
              </div>
            </div>
          </div>

          <div className={"flex flex-col gap-y-2 justify-start items-start"}>
            <p className={"text-neutral-400 text-md uppercase grow"}>
              Documentos y Otros
            </p>
            <div className={"flex flex-col w-full"}>
              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>Carne SUCAMEC</span>
                {supervision.formDataPageKnowledge.carneSucamec.value === true
                  ? "Si"
                  : "No"}
              </div>

              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>
                  Licencia de Arma
                </span>
                {supervision.formDataPageKnowledge.weaponLicense.value === true
                  ? "Si"
                  : "No"}
              </div>

              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>Curso SUCAMEC</span>
                {supervision.formDataPageKnowledge.courseSucamec.value === true
                  ? "Si"
                  : "No"}
              </div>

              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>
                  Procedimiento de Seguridad
                </span>
                {supervision.formDataPageKnowledge.securityKnowledge.value ===
                true
                  ? "Si"
                  : "No"}
              </div>

              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>
                  Procedimiento Contra Incendios
                </span>
                {supervision.formDataPageKnowledge.fireFightKnowledge.value ===
                true
                  ? "Si"
                  : "No"}
              </div>

              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>
                  Tecnicas de Tiro
                </span>
                {supervision.formDataPageKnowledge.shootingKnowledge.value ===
                true
                  ? "Si"
                  : "No"}
              </div>

              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>
                  Atencion al Cliente
                </span>
                {supervision.formDataPageKnowledge.customerSupportKnowledge
                  .value === true
                  ? "Si"
                  : "No"}
              </div>
            </div>
          </div>

          <div className={"flex flex-col gap-y-2 justify-start items-start"}>
            <p className={"text-neutral-400 text-md uppercase grow"}>
              Armamento
            </p>
            <div className={"flex flex-col w-full"}>
              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>Arma letal</span>
                {supervision.formDataPageWeapon.weaponIsLethal.value === true
                  ? "Si"
                  : "No"}
              </div>
              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>Modelo Arma</span>
                {supervision.formDataPageWeapon.weaponModel.value}
              </div>
              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>
                  Numero Serie Arma
                </span>
                {supervision.formDataPageWeapon.weaponSerialNumber.value}
              </div>
              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>Caja Metalica</span>
                {supervision.formDataPageWeapon.weaponMetallicBox.value === true
                  ? "Si"
                  : "No"}
              </div>
              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>Kevlar</span>
                {supervision.formDataPageWeapon.kevlar.value === true
                  ? "Si"
                  : "No"}
              </div>
              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>Munición</span>
                {supervision.formDataPageWeapon.ammunition.value === true
                  ? "Si"
                  : "No"}
              </div>
              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>Observación</span>
                {supervision.formDataPageWeapon.weaponReviewComment.value}
              </div>
            </div>
          </div>

          <div className={"flex flex-col gap-y-2 justify-start items-start"}>
            <p className={"text-neutral-400 text-md uppercase grow"}>
              Uniforme
            </p>
            <div className={"flex flex-col w-full"}>
              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>Camisa</span>
                {supervision.formDataPageVigilant.uniformTshirt.value === true
                  ? "Si"
                  : "No"}
              </div>

              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>Casaca / saco</span>
                {supervision.formDataPageVigilant.uniformJacket.value === true
                  ? "Si"
                  : "No"}
              </div>

              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>Pantalon</span>
                {supervision.formDataPageVigilant.uniformPants.value === true
                  ? "Si"
                  : "No"}
              </div>

              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>Corbata</span>
                {supervision.formDataPageVigilant.uniformTie.value === true
                  ? "Si"
                  : "No"}
              </div>

              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>Gorra</span>
                {supervision.formDataPageVigilant.uniformCap.value === true
                  ? "Si"
                  : "No"}
              </div>

              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>
                  Fotocheck / Cinto
                </span>
                {supervision.formDataPageVigilant.uniformFotoCheck.value ===
                true
                  ? "Si"
                  : "No"}
              </div>

              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>
                  Cartuchera / Correaje
                </span>
                {supervision.formDataPageVigilant.uniformPouch.value === true
                  ? "Si"
                  : "No"}
              </div>

              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>Silbato</span>
                {supervision.formDataPageVigilant.uniformWhistle.value === true
                  ? "Si"
                  : "No"}
              </div>

              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>Vara</span>
                {supervision.formDataPageVigilant.uniformStick.value === true
                  ? "Si"
                  : "No"}
              </div>

              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>Pullover</span>
                {supervision.formDataPageVigilant.uniformPullover.value === true
                  ? "Si"
                  : "No"}
              </div>

              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>Funda Chaleco</span>
                {supervision.formDataPageVigilant.uniformVestholster.value ===
                true
                  ? "Si"
                  : "No"}
              </div>
            </div>
          </div>

          <div className={"flex flex-col gap-y-2 justify-start items-start"}>
            <p className={"text-neutral-400 text-md uppercase grow"}>EPPs</p>
            <div className={"flex flex-col w-full"}>
              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>
                  Botas / Zapatos
                </span>
                {supervision.formDataPageVigilant.uniformEPPShoes.value === true
                  ? "Si"
                  : "No"}
              </div>

              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>Casco</span>
                {supervision.formDataPageVigilant.uniformEPPHelmet.value ===
                true
                  ? "Si"
                  : "No"}
              </div>

              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>Lentes</span>
                {supervision.formDataPageVigilant.uniformEPPGlasses.value ===
                true
                  ? "Si"
                  : "No"}
              </div>

              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>Tapones</span>
                {supervision.formDataPageVigilant.uniformEPPPlugs.value === true
                  ? "Si"
                  : "No"}
              </div>

              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>
                  Comentario Uniforme
                </span>
                {supervision.formDataPageVigilant.uniformComment.value}
              </div>
            </div>
          </div>

          <div className={"flex flex-col gap-y-2 justify-start items-start"}>
            <p className={"text-neutral-400 text-md uppercase grow"}>
              Presentación
            </p>
            <div className={"flex flex-col w-full"}>
              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>
                  Asero Personal
                </span>
                {supervision.formDataPageVigilant.uniformCleanniless.value ===
                true
                  ? "Si"
                  : "No"}
              </div>

              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <span className={"font-semibold uppercase"}>
                  Brillo al Agua
                </span>
                {supervision.formDataPageVigilant.uniformWatershine.value ===
                true
                  ? "Si"
                  : "No"}
              </div>
            </div>
          </div>

          <div className={"flex flex-col gap-y-2 justify-start items-start"}>
            <p className={"text-neutral-400 text-md uppercase grow"}>
              {" "}
              Foto de Personal, Equipos y Otros{" "}
            </p>
          </div>

          {supervision.peopleVerification ? (
            <div className="w-full overflow-x-auto flex flex-row gap-4">
              {supervision.peopleVerification.map((item, i) => (
                <div
                  key={`person-${i}`}
                  className="min-w-[200px] border p-2 rounded-lg shadow-md"
                >
                  <div className="flex flex-col gap-y-2">
                    <span className="font-semibold uppercase">DNI:</span>{" "}
                    {item.dni}
                    <span className="font-semibold uppercase">
                      Nombre:
                    </span>{" "}
                    {item.name}
                  </div>

                  {/* Contenedor de fotos con flex-wrap */}
                  <div className="flex flex-wrap gap-2 mt-2">
                    {item.photoPaths.map((path, j) => (
                      <PathImageLoader
                        key={`photo-${i}-${j}`}
                        path={path}
                        className="object-cover aspect-square rounded-2xl max-h-96"
                      />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <span className={"italic"}>No se añadieron archivos.</span>
          )}

          {/*{supervision.peopleVerification ? (
            <div>
              <div className={"w-full overflow-x-auto flex flex-row gap-2"}>
                {supervision.peopleVerification.map((item, i) => (
                  <div>
                    <div className={"flex flex-row gap-x-2 flex-wrap"}>
                      <span className={"font-semibold uppercase"}>DNI</span>
                      {item.dni}
                    </div>
                    <div className={"flex flex-row gap-x-2 flex-wrap"}>
                      <span className={"font-semibold uppercase"}>Nombre</span>
                      {item.name}
                    </div>

                    {item.photoPaths.map((path, i) => (
                      <PathImageLoader
                        key={`pitem-${i}`}
                        path={item.photoPaths[i]}
                        className={
                          "object-cover aspect-square rounded-2xl max-h-96"
                        }
                      />
                    ))}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <span className={"italic"}>No se añadieron archivos.</span>
          )} */}
        </div>
      </div>
    </div>
  );
};

import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import React, { useState, useEffect } from "react";

import AuthPage from "./ui/page/auth";
import { Loader } from "rsuite";
import { EmailVerifiedPage } from "./ui/page/emailverified";
import { AuthVerificationPage } from "./ui/page/auth/verify";
import DashboardPage from "./ui/page/dashboard";
import { DashboardHomePane } from "./ui/dashboard/home";
import { MainUserPaneContent } from "./ui/dashboard/userlist/main";
import UnityPanel from "./ui/dashboard/unitylist";
import { UnityPage } from "./ui/dashboard/unity";
import { ShiftPaneContent } from "./ui/dashboard/operator/shift";
import { AccountTypePage } from "./ui/dashboard/operator/type";
import { RolePaneContent } from "./ui/dashboard/userlist/role";
import { NewsPage } from "./ui/dashboard/news";
import { BadRequestState } from "./ui/components/state/badrequest";
import FirebaseAuth from "./data/source/FirebaseAuth";
import { UserInfoTab } from "./ui/dashboard/user/info";
import { UserAttendanceTab } from "./ui/dashboard/user/attendance";
import { UserIncidentTab } from "./ui/dashboard/user/incident";
import { UserPatrolTab } from "./ui/dashboard/user/patrol";
import { UserLocationTab } from "./ui/dashboard/user/location";
import { isNil } from "lodash";
import UserRepository from "./data/repository/UserRepository";
import { UserPage } from "./ui/dashboard/user";
import UnityRepository from "./data/repository/UnityRepository";
import { UnityMainPane } from "./ui/dashboard/unity/main";
import { UnityUserListTab } from "./ui/dashboard/unity/user";
import { UnityAttendance } from "./ui/dashboard/unity/attendance";
import { UnityPatrol } from "./ui/dashboard/unity/patrol";
import { UnityIncident } from "./ui/dashboard/unity/incident";
import { UnityZonePage } from "./ui/dashboard/unity/zone";
import { UnityZoneForUnityPage } from "./ui/dashboard/unity/zoneUnity";
import { TableColumnProvider } from "./ui/context/TableContext";
import { AuthContextValue, UserAuthProvider } from "./ui/context/UserContext";
import { UserAttendanceGroupPage } from "./ui/dashboard/attendance-group";
import { ByRequestAttendanceGroup } from "./ui/dashboard/attendance-group/request";
import { LiveAttendanceGroup } from "./ui/dashboard/attendance-group/live";
import { AttendanceGroupOverview } from "./ui/dashboard/attendance-group/overview";

import { UserAttendanceGroupSupPage } from "./ui/dashboard/attendance-group-sup";
import { LiveAttendanceGroupSup } from "./ui/dashboard/attendance-group-sup/live";
import { ByRequestAttendanceGroupSup } from "./ui/dashboard/attendance-group-sup/request";
import { AttendanceGroupOverviewSup } from "./ui/dashboard/attendance-group-sup/overview";

import { AttendancePage } from "./ui/dashboard/attendance";
import { AttendanceSupPage } from "./ui/dashboard/attendance-sup";

import { AttendanceRepository } from "./data/repository/AttendanceRepository";
import { AttendanceSupRepository } from "./data/repository/AttendanceSupRepository";
import { UserPatrolGroupPage } from "./ui/dashboard/patrol-group";
import { PatrolGroupOverview } from "./ui/dashboard/patrol-group/main";
import { ByRequestPatrolGroup } from "./ui/dashboard/patrol-group/request";
import { LivePatrolGroup } from "./ui/dashboard/patrol-group/live";

import { UserPatrolGroupSupPage } from "./ui/dashboard/patrol-group-sup";
import { ByRequestPatrolGroupSup } from "./ui/dashboard/patrol-group-sup/request";
import { PatrolGroupSupOverview } from "./ui/dashboard/patrol-group-sup/main";
import { LivePatrolSupGroup } from "./ui/dashboard/patrol-group-sup/live";

import { UserSupervisionUnityGroupPage } from "./ui/dashboard/supervision-unity-group";
import { ByRequestSupervisionUnityGroup } from "./ui/dashboard/supervision-unity-group/request";

import { UserIncidentGroupPage } from "./ui/dashboard/incident-group";
import { DashboardHeaderContextProvider } from "./hook/header";
import { ProfilePage } from "./ui/dashboard/profile";
import { IncidentRepository } from "./data/repository/IncidentRepository";
import { IncidentPage } from "./ui/dashboard/incident";
import { PatrolRepository } from "./data/repository/PatrolRepository";
import { PatrolSupRepository } from "./data/repository/PatrolSupRepository";
import { SupervisionRepository } from "./data/repository/SupervisionRepository";

import { PatrolPage } from "./ui/dashboard/patrol";
import { PatrolSupPage } from "./ui/dashboard/patrol-sup";
import { SupervisionUnityPage } from "./ui/dashboard/supervision-unity";
import { SupervisionUnityGroupOverview } from "./ui/dashboard/supervision-unity-group/main";
import { LiveSupervisionUnityGroup } from "./ui/dashboard/supervision-unity-group/live";

import { VisitControlGroupPage } from "./ui/dashboard/visit-control-group";
import { VehicleControlGroupPage } from "./ui/dashboard/vehicle-control-group";
import { GoodsControlGroupPage } from "./ui/dashboard/goods-control-group";
import { VisitControlPage } from "./ui/dashboard/visit-control";
import { VisitControlRepository } from "./data/repository/VisitControlRepository";
import { VehicleControlPage } from "./ui/dashboard/vehicle-control";
import { VehicleControlRepository } from "./data/repository/VehicleControlRepository";
import { GoodsControlPage } from "./ui/dashboard/goods-control";
import { GoodsControlRepository } from "./data/repository/GoodsControlRepository";
import { UserVisitControlTab } from "./ui/dashboard/user/visit-control";
import { UserVehicleControlTab } from "./ui/dashboard/user/vehicle-control";
import { UserGoodsControlTab } from "./ui/dashboard/user/goods-control";
import { UnityVisitControlTab } from "./ui/dashboard/unity/visit-control";
import { UnityVehicleControlTab } from "./ui/dashboard/unity/vehicle-control";
import { UnityGoodsControlTab } from "./ui/dashboard/unity/goods-control";
import { UserTicketsGroupPage } from "./ui/dashboard/tickets";
import { UserTicketsGroupContainerFolderList } from "./ui/dashboard/tickets/container-list";
import { UserTicketsContainerContent } from "./ui/dashboard/tickets/container";
import { TicketRepository } from "./data/repository/TicketRepository";
import { NotificationCenterPage } from "./ui/dashboard/notification-center";
import { DocumentationPage } from "./ui/dashboard/documentation";
import { EventsPage } from "./ui/dashboard/events";

import { StyleProvider } from "./ui/context/StyleContext";
import { StyleRepository } from "./data/repository/StyleTypeRepository";
import { object } from "firebase-functions/v1/storage";
import RoleRepository from "./data/repository/RoleRepository";

//pentracker
const primaryColorsDefault = {
  //colores principales
  colorPrimary: "#C4191C",
  colorSecondary: "#2D2D2D",
  colorThirdy: "#2D2D2D",
  colorFourty: "#2D2D2D",
  colorBtnbg: "#E04A4A",
  colorNavText: "#FFFFFF",
  colorHF: "#2D2D2D",
  colorIconNav: "#FFFFFF",
  colorTitles: "#FFFFFF",
  colorTextLink: "#505050",
  //colores adicionales
  colorTextTable: "#FAFAFA",
  colorDark: "#212121",
  colorBlank: "#FFFFFF",
  colorHover: "#424242",
  colorItemAct: "#575757",
  coloDashBoardBorder: "#C4191C",
};

//any other client
export type primaryColors = {
  colorPrimary: string;
  colorSecondary: string;
  colorThirdy: string;
  colorFourty: string;
  colorBtnbg: string;
  colorNavText: string;
  colorHF: string;
  colorIconNav: string;
  colorTitles: string;
  colorTextLink: string;
  colorTextTable: string;
  colorDark: string;
  colorBlank: string;
  colorHover: string;
  colorItemAct: string;
  coloDashBoardBorder: string;
};

function setStylesWithResult(primaryColors: primaryColors) {
  const stylePage = {
    // items
    // thecolor: `${primaryColors.colorPrimary}`,
    // iconos
    "--rs-iconbtn-primary-activated-addon-bg": `${primaryColors.colorPrimary}`,
    "--rs-iconbtn-primary-activated-addon-color": `${primaryColors.colorBlank}`,
    "--rs-iconbtn-primary-addon-bg": `${primaryColors.colorBtnbg}`,
    "--rs-iconbtn-primary-addon-color": `${primaryColors.colorThirdy}`,

    // botones
    "--rs-btn-primary-text": `${primaryColors.colorThirdy}`,
    "--rs-btn-primary-bg": `${primaryColors.colorBtnbg}`,
    "--rs-btn-primary-text-hover": `${primaryColors.colorBlank}`,
    "--rs-btn-primary-hover-bg": `${primaryColors.colorHover}`,

    // sidenav
    iconNavColor: `${primaryColors.colorIconNav}`,
    "--rs-sidenav-subtitles-color-text": `${primaryColors.colorBlank}`,
    "--rs-sidenav-default-nav-bg": `${primaryColors.colorPrimary}`,
    "--rs--sidenav-default-item-font-size": "14px",
    "--rs--sidenav-default-item-text-color": `${primaryColors.colorNavText}`,
    "--rs-sidenav-default-item-active-color": `${primaryColors.colorItemAct}`,
    "--rs-sidenav-default-item-bg": `${primaryColors.colorPrimary}`,
    //   - dropdown
    "--rs-sidenav-default-menu-color-text": `${primaryColors.colorNavText}`,
    "--rs-sidenav-default-menu-bg": `${primaryColors.colorPrimary}`,
    // act- activo
    "--rs-sidenav-default-hover-bg": "transparent",
    "--rs-sidenav-default-hover-color-text": `${primaryColors.colorItemAct}`,

    // header and footer
    "--rs-sidenav-default-dashboard-header-bg": `${primaryColors.colorHF}`,
    "--rs-sidenav-default-dashboard-footer-bg": `${primaryColors.colorHF}`,

    "--rs-sidenav-default-dashboard-border-color": `${primaryColors.coloDashBoardBorder}`,

    // boton hover (mouse en boton)
    "--rs-btn-ghost-text": `${primaryColors.colorThirdy}`,
    "--rs-btn-ghost-border": `${primaryColors.colorSecondary}`,
    "--rs-btn-ghost-hover-text": `${primaryColors.colorPrimary}`,
    "--rs-btn-ghost-hover-border": `${primaryColors.colorPrimary}`,
    "--rs-btn-ghost-hover-bg": `${primaryColors.colorHover}`,

    "--rs-iconbtn-activated-addon": `${primaryColors.colorPrimary}`,

    // links o texto linkeado
    "--rs-picker-value": `${primaryColors.colorPrimary}`,
    "--rs-text-link": `${primaryColors.colorTextLink}`,
    "--rs-text-link-hover": `${primaryColors.colorTextLink}`,

    // vista usuario
    "--rs-color-user-avatar": `${primaryColors.colorPrimary}`,
    "--rs-color-user-tab": `${primaryColors.colorPrimary}`,
    "--rs-color-user-text": `${primaryColors.colorSecondary}`,

    //titulos
    "--rs-color-titles": `${primaryColors.colorTitles}`,
  };

  return stylePage;
}

function App() {
  const [primaryColor, setPrimaryColor] = useState({});
  const [style, setStyle] = useState({});

  useEffect(() => {
    // async function getDataStyles() {
    //   await StyleRepository.getList(true);
    // }
    // funcion para obtener estilos de la base de datos
    async function getStyles() {
      const appUser = await UserRepository.getCurrentUser();
      if (!appUser) throw new Error("Invalid session");
      if (appUser?.isClient()) {
        // obtener los estilos
        await StyleRepository.getList(true);

        const fetchStylesPage = await StyleRepository.getStyle(
          appUser.user.uid!!
        );
        
        if (fetchStylesPage) {
          setPrimaryColor(fetchStylesPage.primaryColors!!);
          const stylePage = setStylesWithResult(
            fetchStylesPage.primaryColors!!
          );
          setStyle(stylePage);
        } else {
          setPrimaryColor(primaryColorsDefault);
          const stylePage = setStylesWithResult(primaryColorsDefault);
          setStyle(stylePage);
        }
      } else {
        // no es cliente
        setPrimaryColor(primaryColorsDefault);
        const stylePage = setStylesWithResult(primaryColorsDefault);
        setStyle(stylePage);
      }
    }
    // getDataStyles();
    getStyles();
  }, []);

  const routes = [
    {
      path: "/dashboard",
      element: (
        <DashboardHeaderContextProvider>
          <StyleProvider stylePage={style} primaryColors={primaryColor}>
            <UserAuthProvider>
              <TableColumnProvider>
                <DashboardPage />
              </TableColumnProvider>
            </UserAuthProvider>
          </StyleProvider>
        </DashboardHeaderContextProvider>
      ),
      loader: async (): Promise<AuthContextValue> => {
        const appUser = await UserRepository.getCurrentUser();
        if (!appUser) throw new Error("Invalid session");
        return {
          appUser,
        };
      },
      errorElement: <Navigate to={"/auth"} />,
      children: [
        {
          path: "/dashboard/",
          element: <DashboardHomePane />,
        },
        {
          path: "/dashboard/users",
          element: <MainUserPaneContent />,
        },
        {
          path: "/dashboard/notification-center",
          element: <NotificationCenterPage />,
        },
        {
          path: "/dashboard/documentation",
          element: <DocumentationPage />,
        },
        {
          path: "/dashboard/events",
          element: <EventsPage />,
        },
        {
          path: "/dashboard/attendance-group",
          element: <UserAttendanceGroupPage />,
          children: [
            {
              path: "/dashboard/attendance-group",
              element: <AttendanceGroupOverview />,
            },
            {
              path: "/dashboard/attendance-group/request",
              element: <ByRequestAttendanceGroup />,
            },
            {
              path: "/dashboard/attendance-group/live",
              element: <LiveAttendanceGroup />,
            },
          ],
        },
        {
          path: "/dashboard/attendance-group-sup",
          element: <UserAttendanceGroupSupPage />,
          children: [
            {
              path: "/dashboard/attendance-group-sup",
              element: <AttendanceGroupOverviewSup />,
            },
            {
              path: "/dashboard/attendance-group-sup/request",
              element: <ByRequestAttendanceGroupSup />,
            },
            {
              path: "/dashboard/attendance-group-sup/live",
              element: <LiveAttendanceGroupSup />,
            },
          ],
        },
        {
          path: "/dashboard/attendance",
          element: <AttendancePage />,
          loader: async ({ request }: any) => {
            const url = new URL(request.url);
            const entry = url.searchParams.get("entry");
            if (!entry) {
              throw new Error("Ingreso inválido.");
            }
            return await AttendanceRepository.getAttendance(entry);
          },
          errorElement: <BadRequestState />,
        },
        {
          path: "/dashboard/attendanceSup",
          element: <AttendanceSupPage />,
          loader: async ({ request }: any) => {
            const url = new URL(request.url);
            const entry = url.searchParams.get("entry");
            if (!entry) {
              throw new Error("Ingreso inválido.");
            }
            return await AttendanceSupRepository.getAttendance(entry);
          },
          errorElement: <BadRequestState />,
        },
        {
          path: "/dashboard/incident",
          element: <IncidentPage />,
          loader: async ({ request }: any) => {
            const url = new URL(request.url);
            const reference = url.searchParams.get("reference");
            if (!reference) {
              throw new Error("Incidencia inválida.");
            }
            return await IncidentRepository.getIncident(reference);
          },
          errorElement: <BadRequestState />,
        },
        {
          path: "/dashboard/visit-control",
          element: <VisitControlPage />,
          loader: async ({ request }: any) => {
            const url = new URL(request.url);
            const reference = url.searchParams.get("reference");
            if (!reference) {
              throw new Error("Control de Visita inválido.");
            }
            return await VisitControlRepository.getVisitControl(reference);
          },
          errorElement: <BadRequestState />,
        },
        {
          path: "/dashboard/vehicle-control",
          element: <VehicleControlPage />,
          loader: async ({ request }: any) => {
            const url = new URL(request.url);
            const reference = url.searchParams.get("reference");
            if (!reference) {
              throw new Error("Control Vehicular inválido.");
            }
            return await VehicleControlRepository.getVehicleControl(reference);
          },
          errorElement: <BadRequestState />,
        },
        {
          path: "/dashboard/goods-control",
          element: <GoodsControlPage />,
          loader: async ({ request }: any) => {
            const url = new URL(request.url);
            const reference = url.searchParams.get("reference");
            if (!reference) {
              throw new Error("Control de Bienes y Equipos inválido.");
            }
            return await GoodsControlRepository.getGoodsControl(reference);
          },
          errorElement: <BadRequestState />,
        },
        {
          path: "/dashboard/patrol",
          element: <PatrolPage />,
          loader: async ({ request }: any) => {
            const url = new URL(request.url);
            const reference = url.searchParams.get("reference");
            if (!reference) {
              throw new Error("Ronda inválida.");
            }
            return await PatrolRepository.getPatrol(reference);
          },
          errorElement: <BadRequestState />,
        },
        {
          path: "/dashboard/patrol-group",
          element: <UserPatrolGroupPage />,
          children: [
            {
              path: "/dashboard/patrol-group",
              element: <PatrolGroupOverview />,
            },
            {
              path: "/dashboard/patrol-group/request",
              element: <ByRequestPatrolGroup />,
            },
            {
              path: "/dashboard/patrol-group/live",
              element: <LivePatrolGroup />,
            },
          ],
        },
        {
          path: "/dashboard/patrolSup",
          element: <PatrolSupPage />,
          loader: async ({ request }: any) => {
            const url = new URL(request.url);
            const reference = url.searchParams.get("reference");
            if (!reference) {
              throw new Error("Ronda inválida.");
            }
            return await PatrolSupRepository.getPatrol(reference);
          },
          errorElement: <BadRequestState />,
        },
        {
          path: "/dashboard/patrol-group-sup",
          element: <UserPatrolGroupSupPage />,
          children: [
            {
              path: "/dashboard/patrol-group-sup",
              element: <PatrolGroupSupOverview />,
            },
            {
              path: "/dashboard/patrol-group-sup/request",
              element: <ByRequestPatrolGroupSup />,
            },
            {
              path: "/dashboard/patrol-group-sup/live",
              element: <LivePatrolSupGroup />,
            },
          ],
        },
        {
          path: "/dashboard/patrol",
          element: <PatrolPage />,
          loader: async ({ request }: any) => {
            const url = new URL(request.url);
            const reference = url.searchParams.get("reference");
            if (!reference) {
              throw new Error("Ronda inválida.");
            }
            return await PatrolRepository.getPatrol(reference);
          },
          errorElement: <BadRequestState />,
        },
        {
          path: "/dashboard/patrol-group",
          element: <UserPatrolGroupPage />,
          children: [
            {
              path: "/dashboard/patrol-group",
              element: <PatrolGroupOverview />,
            },
            {
              path: "/dashboard/patrol-group/request",
              element: <ByRequestPatrolGroup />,
            },
            {
              path: "/dashboard/patrol-group/live",
              element: <LivePatrolGroup />,
            },
          ],
        },
        {
          path: "/dashboard/supervision",
          element: <SupervisionUnityPage />,
          loader: async ({ request }: any) => {
            const url = new URL(request.url);
            const reference = url.searchParams.get("reference");
            if (!reference) {
              throw new Error("Supervision inválida.");
            }
            return await SupervisionRepository.getSupervision(reference);
          },
          errorElement: <BadRequestState />,
        },
        {
          path: "/dashboard/supervision-unity-group",
          element: <UserSupervisionUnityGroupPage />,
          children: [
            {
              path: "/dashboard/supervision-unity-group",
              element: <SupervisionUnityGroupOverview />,
            },
            {
              path: "/dashboard/supervision-unity-group/request",
              element: <ByRequestSupervisionUnityGroup />,
            },
            {
              path: "/dashboard/supervision-unity-group/live",
              element: <LiveSupervisionUnityGroup />,
            },
          ],
        },
        {
          path: "/dashboard/incident-group",
          element: <UserIncidentGroupPage />,
        },
        {
          path: "/dashboard/visit-control-group",
          element: <VisitControlGroupPage />,
        },
        {
          path: "/dashboard/vehicle-control-group",
          element: <VehicleControlGroupPage />,
        },
        {
          path: "/dashboard/goods-control-group",
          element: <GoodsControlGroupPage />,
        },
        {
          path: "/dashboard/unity",
          element: <UnityPanel />,
        },
        {
          path: "/dashboard/news",
          element: <NewsPage />,
        },
        {
          path: "/dashboard/tickets",
          element: <UserTicketsGroupPage />,
          children: [
            {
              path: "/dashboard/tickets",
              element: <UserTicketsGroupContainerFolderList />,
            },
            {
              path: "/dashboard/tickets/:name",
              element: <UserTicketsContainerContent />,
              loader: async ({ params }: any) => {
                return TicketRepository.getContainer(params.name);
              },
              errorElement: <BadRequestState />,
            },
          ],
        },
        {
          path: "/dashboard/unity/:id",
          element: <UnityPage />,
          loader: async ({ params }: any) => {
            const { id } = params;
            if (isNil(id)) {
              throw new Error("Unity id is not valid.");
            } else {
              return await UnityRepository.getUnity(id);
            }
          },
          errorElement: <BadRequestState />,
          children: [
            {
              path: "/dashboard/unity/:id/main",
              element: <UnityMainPane />,
            },
            {
              path: "/dashboard/unity/:id/user",
              element: <UnityUserListTab />,
            },
            {
              path: "/dashboard/unity/:id/attendance",
              element: <UnityAttendance />,
            },
            {
              path: "/dashboard/unity/:id/patrol",
              element: <UnityPatrol />,
            },
            {
              path: "/dashboard/unity/:id/incident",
              element: <UnityIncident />,
            },
            {
              path: "/dashboard/unity/:id/zone",
              element: <UnityZonePage />,
            },
            {
              path: "/dashboard/unity/:id/zoneView/:zoneId",
              element: <UnityZonePage />,
            },
            {
              path: "/dashboard/unity/:id/zoneForUnity",
              element: <UnityZoneForUnityPage />,
            },
            {
              path: "/dashboard/unity/:id/visit-control",
              element: <UnityVisitControlTab />,
            },
            {
              path: "/dashboard/unity/:id/vehicle-control",
              element: <UnityVehicleControlTab />,
            },
            {
              path: "/dashboard/unity/:id/goods-control",
              element: <UnityGoodsControlTab />,
            },
          ],
        },
        {
          path: "/dashboard/users/:uid",
          element: <UserPage />,
          loader: async ({ params }: any) => {
            const { uid } = params;
            if (isNil(uid)) {
              throw new Error("User id is not valid.");
            } else {
              return await UserRepository.getUser(uid);
            }
          },
          errorElement: <BadRequestState />,
          children: [
            {
              path: "/dashboard/users/:uid/main",
              element: <UserInfoTab />,
            },
            {
              path: "/dashboard/users/:uid/attendance",
              element: <UserAttendanceTab />,
            },
            {
              path: "/dashboard/users/:uid/incident",
              element: <UserIncidentTab />,
            },
            {
              path: "/dashboard/users/:uid/patrol",
              element: <UserPatrolTab />,
            },
            {
              path: "/dashboard/users/:uid/location",
              element: <UserLocationTab />,
            },
            {
              path: "/dashboard/users/:uid/visit-control",
              element: <UserVisitControlTab />,
            },
            {
              path: "/dashboard/users/:uid/vehicle-control",
              element: <UserVehicleControlTab />,
            },
            {
              path: "/dashboard/users/:uid/goods-control",
              element: <UserGoodsControlTab />,
            },
          ],
        },
        {
          path: "/dashboard/shiftlist",
          element: <ShiftPaneContent />,
        },
        {
          path: "/dashboard/labels",
          element: <AccountTypePage />,
        },
        {
          path: "/dashboard/role-list",
          element: <RolePaneContent />,
        },
        {
          path: "/dashboard/news",
          element: <NewsPage />,
        },
        {
          path: "/dashboard/personal-info",
          element: <ProfilePage />,
        },
      ],
    },
    {
      path: "/auth",
      element: <Navigate to={"/dashboard"} />,
      loader: async () => {
        return await FirebaseAuth.getCurrentUser();
      },
      errorElement: <AuthPage />,
    },
    {
      path: "/",
      element: <Navigate to={"/dashboard"} />,
    },
    {
      path: "/auth-emailverified",
      element: <EmailVerifiedPage />,
    },
    {
      path: "/auth-verification",
      element: <Navigate to={"/dashboard"} />,
      loader: async () => {
        return await FirebaseAuth.getCurrentUser();
      },
      errorElement: <AuthVerificationPage />,
    },
    {
      path: "*",
      element: <BadRequestState />,
    },
  ];

  const router = createBrowserRouter(routes);
  return (
    <RouterProvider
      router={router}
      fallbackElement={
        <Loader size={"lg"} center backdrop content={"Cargando..."} vertical />
      }
    />
  );
}

export default App;
